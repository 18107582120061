import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultHomeBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_home_banner', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListHomeBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_home_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getHomeBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_home_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addHomeBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_home_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editHomeBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_home_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteHomeBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_home_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    homeBannerOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/home_banner_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageHomeBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_home_banner/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
